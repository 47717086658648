export default [
  {
    path: "/engagements",
    name: "engagements",
    component: () => import("@/views/engagement/Engagements.vue"),
    meta: {
      pageTitle: "Assignment",
      breadcrumb: [
        {
          text: "Engagements",
          active: true,
        },
      ],
      authRequired: true,
    },
  },
  {
    path: "/details-engagement/:id",
    name: "details-engagement",
    component: () => import("@/views/engagement/DetailsEngagement.vue"),
    meta: {
      pageTitle: "Engagements",
      breadcrumb: [
        {
          text: "Engagements",
          active: true,
        },
      ],
      authRequired: true,
    },
  },
  {
    path: "/assignment-details/:id",
    name: "assignment-details",
    component: () => import("@/views/engagement/AssignmentDetails.vue"),
    meta: {
      pageTitle: "Assignment Details",
      breadcrumb: [
        {
          text: "Engagements",
          active: true,
        },
      ],
      authRequired: true,
    },
  },
  {
    path: "/assignment-proposal/:id",
    name: "assignment-proposal",
    component: () => import("@/views/engagement/AssignmentProposal.vue"),
  },
  {
    path: "/offer-accepted",
    name: "offer-accepted",
    component: () => import("@/views/engagement/OfferAccepted.vue"),
  },
  {
    path: "/timing-confirmation",
    name: "timing-confirmation",
    component: () => import("@/views/engagement/TimingConfirmation.vue"),
  },
  {
    path: "/interview-confirmation",
    name: "interview-confirmation",
    component: () => import("@/views/engagement/InterviewConfirmation.vue"),
  },
  {
    path: "/on-boarding",
    name: "on-boarding",
    component: () => import("@/views/engagement/OnBoarding.vue"),
  },
  // {
  //   path: '/client-approval',
  //   name: 'client-approval',
  //   component: () => import('@/views/engagement/ClientApproval.vue'),
  // },
  // {
  //   path: '/expert-payment',
  //   name: 'expert-payment',
  //   component: () => import('@/views/engagement/ExpertPayment.vue'),
  // },
  // extra
  // {
  //   path: "/add-assignment",
  //   name: "add-assignment",
  //   component: () => import("@/views/engagement/AddNewAssignment.vue"),
  // },
  // {
  //   path: "/add-client",
  //   name: "add-client",
  //   component: () => import("@/views/engagement/AddClient.vue"),
  // },
  // {
  //   path: "/assignment-details/:id",
  //   name: "assignment-details",
  //   component: () => import("@/views/engagement/AssignmentDetails.vue"),
  // },
  // {
  //   path: "/client-details",
  //   name: "client-details",
  //   component: () => import("@/views/engagement/ClientDetails.vue"),
  // },
]
