export default {
  SAVE_USER_DATA: (state, payload) => {
    state.user = payload
    sessionStorage.setItem('USER', JSON.stringify(payload))
  },

  SAVE_USER_ACCESS_TOKEN: (state, payload) => {
    state.access_token = payload
    sessionStorage.setItem('ACCESS_TOKEN', payload)
  },

  LOGOUT_USER: state => {
    state.user.data = {}
    state.user.token = null

    sessionStorage.clear()
  },

  SAVE_INDUSTRIES: (state, payload) => {
    state.industries = payload
    // sessionStorage.setItem('INDUSTRIES', JSON.stringify(payload))
  },

  SAVE_CANDIDATE_FUNCTIONS: (state, payload) => {
    state.candidate_functions = payload
    // sessionStorage.setItem('CANDIDATE_FUNCTIONS', JSON.stringify(payload))
  },

  SAVE_SUB_FUNCTIONS: (state, payload) => {
    state.sub_functions = payload
    // sessionStorage.setItem('SUB_FUNCTIONS', JSON.stringify(payload))
  },

  SAVE_COUNTRIES: (state, payload) => {
    state.countries = payload
    // sessionStorage.setItem('COUNTRIES', JSON.stringify(payload))
  },

  SAVE_EXPERTISES: (state, payload) => {
    state.expertises = payload
    // sessionStorage.setItem('COUNTRIES', JSON.stringify(payload))
  },
}
