import {
  getHomeRouteForLoggedInUser,
  getUserData,
  isUserLoggedIn,
} from "@/auth/utils"
// import { canNavigate } from "@/libs/acl/routeProtection"
// import { canNavigate } from "@/libs/acl/routeProtection"
import Vue from "vue"
import VueRouter from "vue-router"
import auth from "./routes/auth"
import engagement from "./routes/engagement"
import error from "./routes/error"
import utilPages from "./routes/utilPages"

Vue.use(VueRouter)

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [...auth, ...utilPages, ...engagement, ...error],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  const userData = getUserData()
  // console.log(userData.role.code)

  // if (!isLoggedIn) return next({ name: "login" })
  // if (!canNavigate(to)) {
  //   // Redirect to login if not logged in

  //   // If logged in => not authorized
  //   // return next({ name: 'misc-not-authorized' })
  // }
  if (to.meta.authRequired && !isLoggedIn) {
    next({ name: "login" })
  }

  // if (to.meta.authRequired && isLoggedIn) {
  //   next(getHomeRouteForLoggedInUser(userData ? userData.role.code : null))
  // }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next(getHomeRouteForLoggedInUser(userData ? userData.role.code : null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg")
  if (appLoading) {
    appLoading.style.display = "none"
  }
})

export default router
