export default [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/auth/Login.vue"),
    meta: {
      layout: "full",
      redirectIfLoggedIn: true,
      authRequired: false,
    },
  },
  {
    path: "/register",
    name: "auth-register",
    component: () => import("@/views/auth/Register.vue"),
    meta: {
      layout: "full",
      redirectIfLoggedIn: true,
      authRequired: false,
    },
  },
  {
    path: "/reset-password",
    name: "auth-reset-password",
    component: () => import("@/views/auth/ResetPassword.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
      authRequired: false,
    },
  },
  {
    path: "/forgot-password",
    name: "auth-forgot-password",
    component: () => import("@/views/auth/ForgotPassword.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      redirectIfLoggedIn: true,
      authRequired: false,
    },
  },
  {
    path: "/auth/coming-soon",
    name: "misc-coming-soon",
    component: () => import("@/views/auth/ComingSoon.vue"),
    meta: {
      layout: "full",
      authRequired: false,
    },
  },
  {
    path: "/auth/not-authorized",
    name: "misc-not-authorized",
    component: () => import("@/views/auth/NotAuthorized.vue"),
    meta: {
      layout: "full",
      resource: "Auth",
      authRequired: false,
    },
  },

  {
    path: "/auth/under-maintenance",
    name: "misc-under-maintenance",
    component: () => import("@/views/auth/UnderMaintenance.vue"),
    meta: {
      layout: "full",
      authRequired: false,
    },
  },
  {
    path: "/api/social-callback/linkedin",
    redirect: "/login",
    // path: "//api/social-callback/linkedin",
    // name: "misc-under-maintenance",
    // component: () => import("@/views/auth/UnderMaintenance.vue"),
    // meta: {
    //   layout: "full",
    //   authRequired: false,
    // },
  },
]
