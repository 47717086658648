import Vue from "vue"
import Vuex from "vuex"

// Modules
import app from "./app"
import appConfig from "./app-config"
import verticalMenu from "./vertical-menu"

import auth from "./auth"
import candidateProfile from "./candidateProfile"
import utilities from "./utilities"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    candidateProfile,
    utilities,
  },
  strict: process.env.DEV,
})
