// eslint-disable-next-line import/no-cycle
import axios from '@/libs/axios'

export default {
  countries: ({ commit }) => {
    axios.get('/countries')
      .then(res => {
        commit('SAVE_COUNTRIES_DATA', res.data.countries)
      })
  },

  expertise: ({ commit }) => {
    axios.get('/expertise')
      .then(res => {
        commit('SAVE_EXPERTISE_DATA', res.data.expertise)
      })
  },

  industry: ({ commit }) => {
    axios.get('/industries')
      .then(res => {
        commit('SAVE_INDUSTRY_DATA', res.data.industries)
      })
  },

  candidate_functions: ({ commit }) => {
    axios.get('/functions')
      .then(res => {
        commit('SAVE_FUNCTION_DATA', res.data.candidateFunctions)
      })
  },

  sub_functions: ({ commit }) => {
    axios.get('/sub_functions')
      .then(res => {
        commit('SAVE_SUB_FUNCTION_DATA', res.data.sub_functions)
      })
  },

  skills: ({ commit }) => {
    axios.get('/skills')
      .then(res => {
        commit('SAVE_SKILL_DATA', res.data.skills)
      })
  },
}
