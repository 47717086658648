// axios
import axios from "axios"

// axiosClient.interceptors.request.use(config => {
//   // eslint-disable-next-line no-param-reassign
//   config.headers.Authorization = `Bearer ${store.state.user.token}`

//   return config
// })

const authorizedApiClient = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:8000/api/"
      : "https://api.testinghk123.online/api/",
  timeout: 5000,
  headers: {
    "Access-Control-Allow-Origin": "*",
    // Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  },
})
authorizedApiClient.interceptors.request.use((config) => {
  if (localStorage.getItem("accessToken")) {
    // or get it from localStorage
    config.headers.Authorization = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`
  }
  return config
})

export default authorizedApiClient
