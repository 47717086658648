// eslint-disable-next-line import/no-cycle
import axios from "@/libs/axios"

export default {
  profileImage: ({ dispatch }, payload) => {
    axios.post("/profile", payload).then((res) => {
      if (res.data.status === "success") {
        dispatch("auth/reload_user", null, { root: true })
      }
    })
  },

  updateResume: ({ dispatch }, payload) => {
    const user = JSON.parse(sessionStorage.getItem("USER"))

    axios.put(`update/resume/${user.id}`, payload).then((res) => {
      dispatch("auth/reload_user", null, { root: true })
      return res
    })
  },

  updateResumeSkills: ({ dispatch }, payload) => {
    const user = JSON.parse(sessionStorage.getItem("USER"))

    axios.put(`update/resume/skills/${user.id}`, payload).then((res) => {
      dispatch("getSkills")
      return res
    })
  },

  basicDetails: ({ dispatch }) => {
    dispatch("auth/reset_user", null, { root: true })
  },

  profileQualificationDetails: ({ dispatch }, payload) =>
    axios.post("/profile/qualification", payload).then((res) => {
      dispatch("auth/reload_user", null, { root: true })
      return res
    }),

  profileExperienceDetails: ({ dispatch }, payload) =>
    axios.post("/profile/experience", payload).then((res) => {
      dispatch("auth/reload_user", null, { root: true })
      return res
    }),

  getSkills: ({ commit }) =>
    axios.get("/resume/skills").then((res) => {
      commit("USER_SKILLS", res.data.skills)

      return res
    }),
}
