export default {
  SAVE_COUNTRIES_DATA: (state, payload) => {
    state.countries = payload
    sessionStorage.setItem('COUNTRIES', JSON.stringify(payload))
  },

  SAVE_EXPERTISE_DATA: (state, payload) => {
    state.expertise = payload
    sessionStorage.setItem('EXPERTISE', JSON.stringify(payload))
  },

  SAVE_INDUSTRY_DATA: (state, payload) => {
    state.industries = payload
    sessionStorage.setItem('INDUSTRY', JSON.stringify(payload))
  },

  SAVE_FUNCTION_DATA: (state, payload) => {
    state.functions = payload
    sessionStorage.setItem('FUNCTIONS', JSON.stringify(payload))
  },

  SAVE_SUB_FUNCTION_DATA: (state, payload) => {
    state.subFunctions = payload
    sessionStorage.setItem('SUB_FUNCTION', JSON.stringify(payload))
  },

  SAVE_SKILL_DATA: (state, payload) => {
    state.skills = payload
    sessionStorage.setItem('SKILLS', JSON.stringify(payload))
  },
}
