export default [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/Home.vue"),
    meta: {
      pageTitle: "Dashboard",
      breadcrumb: [
        {
          text: "Dashboard",
          active: true,
        },
      ],
      authRequired: true,
    },
  },
  // {
  //   path: "/my-profile",
  //   name: "my-profile",
  //   component: () => import("@/views/myprofile/MyProfile.vue"),
  //   meta: {
  //     pageTitle: "Profile",
  //     breadcrumb: [
  //       {
  //         text: "My Profile",
  //         active: true,
  //       },
  //     ],
  //     authRequired: true,
  //   },
  // },
  {
    path: "/second-page",
    name: "second-page",
    component: () => import("@/views/SecondPage.vue"),
    meta: {
      pageTitle: "Second Page",
      breadcrumb: [
        {
          text: "Second Page",
          active: true,
        },
      ],
      authRequired: true,
    },
  },
]
