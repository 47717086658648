export default {
  user: JSON.parse(sessionStorage.getItem('USER')),
  access_token: sessionStorage.getItem('ACCESS_TOKEN'),

  // industries: JSON.parse(sessionStorage.getItem('INDUSTRIES')),
  // candidate_functions: JSON.parse(sessionStorage.getItem('CANDIDATE_FUNCTIONS')),
  // sub_functions: JSON.parse(sessionStorage.getItem('SUB_FUNCTIONS')),
  // countries: JSON.parse(sessionStorage.getItem('COUNTRIES')),
  industries: [],
  candidate_functions: [],
  sub_functions: [],
  countries: [],
  expertises: [],
}
